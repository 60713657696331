import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StudentDashbordComponent } from './student-dashbord/student-dashbord.component';
import { AuthGuard } from '../auth.guard';
import { StudentMaterialsComponent } from './student-materials/student-materials.component';
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { StudentProjectsComponent } from './student-projects/student-projects.component';
import { AboutStudentComponent } from './about-student/about-student.component';
import { StudentAssignmentsComponent } from './student-assignments/student-assignments.component';
import { StudentCoursesComponent } from './student-courses/student-courses.component';


const routes: Routes = [
  { path: '', component: StudentDashbordComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'student-materials', component: StudentMaterialsComponent },
      { path: 'student-profile', component: StudentProfileComponent },
      { path: 'student-projects', component: StudentProjectsComponent },
      { path: 'about-student', component: AboutStudentComponent },
      { path: 'student-assignments', component: StudentAssignmentsComponent },
      { path: 'student-courses', component: StudentCoursesComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentmodelRoutingModule { }
