import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";


// layouts
// import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
// import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
// import { MapsComponent } from "./views/admin/maps/maps.component";
// import { SettingsComponent } from "./views/admin/settings/settings.component";
// import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { QualityanalystComponent } from "./courses/qualityanalyst/qualityanalyst.component";
import { JavascriptComponent } from "./courses/javascript/javascript.component";
import { JavaComponent } from "./courses/java/java.component";
import { SalesforceComponent } from "./courses/salesforce/salesforce.component";
import { NodejsComponent } from "./courses/nodejs/nodejs.component";
import { ReactjsComponent } from "./courses/reactjs/reactjs.component";
import { AngularComponent } from "./courses/angular/angular.component";
import { DevopsComponent } from "./courses/devops/devops.component";
import { SapComponent } from "./courses/sap/sap.component";
import { PythonComponent } from "./courses/python/python.component";
import { AptitudeComponent } from './courses/aptitude/aptitude.component';
import { CybersecurityComponent } from './courses/cybersecurity/cybersecurity.component';
import { InternshipComponent } from './courses/internship/internship.component';
import { TrainingsComponent } from "./courses/trainings/trainings.component";
import { CareersComponent } from './views/careers/careers.component';
import { PrivacyPolicyComponent } from "./courses/privacy-policy/privacy-policy.component";
import { ScrollTotopComponent } from "./courses/scroll-totop/scroll-totop.component";
import { UpcomingBatchesComponent } from "./courses/upcoming-batches/upcoming-batches.component";
import { CarouselComponent } from "./courses/carousel/carousel.component";
import { LoginformComponent } from "./loginform/loginform.component"
import { SignupComponent } from "./signup/signup.component";
import { PaymentComponent } from "./payment/payment.component";


// import { StudentDashbordComponent } from "./student_dashboard/student-dashbord/student-dashbord.componentdashbord.component";
import { AuthGuard } from "./auth.guard";
import { AdminPanelComponent } from "./admin_dashboard/admin-panel/admin-panel.component";
import { AdminDashboardComponent } from "./admin_dashboard/admin-dashboard/admin-dashboard.component";
import { AdminauthGuard } from "./admin_dashboard/admin-dashboard/adminauth.guard";
import { ResourceManagerComponent } from "./admin_dashboard/resource-manager/resource-manager.component";
import { LiveclassesComponent } from "./courses/liveclasses/liveclasses.component";



const routes: Routes = [
  // admin views
  // {
  //   path: "admin",
  //   component: AdminComponent,
  //   children: [
  //     { path: "dashboard", component: DashboardComponent },
  //     { path: "settings", component: SettingsComponent },
  //     { path: "tables", component: TablesComponent },
  //     { path: "maps", component: MapsComponent },
  //     { path: "", redirectTo: "dashboard", pathMatch: "full" },
  //   ],
  // },
  // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
  // no layout views
  { path: "profile", component: ProfileComponent },
  { path: "landing", component: LandingComponent },
  { path: "", component: IndexComponent },
  { path: "qualityanalyst", component: QualityanalystComponent },
  { path: "javascript", component: JavascriptComponent },
  { path: "java", component: JavaComponent },
  { path: "salesforce", component: SalesforceComponent },
  { path: "nodejs", component: NodejsComponent },
  { path: "aptitude", component: AptitudeComponent },
  { path: "cybersecurity", component: CybersecurityComponent },
  { path: "reactjs", component: ReactjsComponent },
  { path: "angular", component: AngularComponent },
  { path: "devops", component: DevopsComponent },
  { path: "sap", component: SapComponent },
  { path: "python", component: PythonComponent },
  { path: "internship", component: InternshipComponent },
  { path: "trainings", component: TrainingsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "scrolltotop", component: ScrollTotopComponent },
  { path: "upcoming-batches", component: UpcomingBatchesComponent },
  { path: "carousel", component: CarouselComponent },
  { path: "loginform", component: LoginformComponent },
  { path: "signup", component: SignupComponent },
  { path: "payment", component: PaymentComponent },
  { path: "liveClasses", component: LiveclassesComponent },

  // { path: 'studentDashbord', component: StudentDashbordComponent, canActivate: [AuthGuard] },
  // { path: 'admin-panel', component: AdminPanelComponent },
  // { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate:[AdminauthGuard] },
  // { path: 'resource-manager', component: ResourceManagerComponent},


  {
    path: 'career-options',
    loadChildren: () => import('./career-options/career-options.module').then(m => m.CareerOptionsModule)
  },
  {
    path: 'dynamic-courses',
    loadChildren: () => import('./dynamic-courses/dynamic-courses.module').then(m => m.DynamicCoursesModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./admin_dashboard/adminmodel.module').then(m => m.AdminmodelModule),
  },
  {
    path: 'student-dashboard',
    loadChildren: () => import('./student_dashboard/studentmodel.module').then(m => m.StudentmodelModule),
  },
  {
    path: 'liveclassesDynamic/:name',
    loadChildren: () => import('./live-classesmodule/live-classesmodule.module').then(m => m.LiveClassesmoduleModule),
  },
  // {
  //   path: 'liveclassesDynamic',
  //   loadChildren: () => import('./live-classesmodule/live-classesmodule.module').then(m => m.LiveClassesmoduleModule),
  // },
  { path: "**", redirectTo: "", pathMatch: "full" },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),],
  exports: [RouterModule],
})
export class AppRoutingModule { }
